import constants from "constants/index";
import Image, { GatsbyImageProps } from "gatsby-image";
import Headline from "./texts/Headline";
import * as React from "react";
import Styled from "styled-components/macro";
import Button from "./buttons/Button";
import Description from "./texts/Description";
import RichDescription from "./texts/RichDescription";
import Tagline from "./texts/Tagline";
import MediaQuery from "./layout/MediaQuery";
import Media from "@uizard-io/styled-media-query";

const TextContainer = Styled.div`
  text-align:center;
  color: white;
  margin-top: 5rem;
  padding: 5rem 0;

  ${Media.greaterThan("medium")`
    margin-top: 5rem;
    padding: 10rem 0;
  `}

  ${Media.greaterThan("1200px")`
    margin-top: 10rem;
    padding: 20rem 0;
  `}
`;

const ContentContainer = Styled.div`
  text-align: center;
  color: white;
  background: rgb(70,102,205);
  background: -moz-linear-gradient(135deg, rgba(70,185,181,0.5) 0%, rgba(70,102,205,1) 50%, rgba(107,25,227,1) 100%);
  background: -webkit-linear-gradient(135deg, rgba(70,185,181,0.5) 0%, rgba(70,102,205,1) 50%, rgba(107,25,227,1) 100%);
  background: linear-gradient(135deg, rgba(70,185,181,0.5) 0%, rgba(70,102,205,1) 50%, rgba(107,25,227,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#46b9b5",endColorstr="#6b19e3",GradientType=1);
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const NavButtonLink = Styled.a`
  
`;

const HeadlineExtended = Styled(Headline)`
  text-align: center;
  font-size: 4rem;
  line-height: 3.6rem;
  font-weight: 500;
  margin: 1.5rem auto;

  ${Media.greaterThan("medium")`
    font-size: 5rem;
    line-height: 4.5rem;
    letter-spacing: -4px;
    max-width: 300px;
  `}

  ${Media.greaterThan("1200px")`
    font-size: 8rem;
    line-height: 7rem;
    letter-spacing: -4px;
    max-width: 600px;
  `}
`;

const DescriptionExtended = Styled(Description)`
  margin: 4rem auto;
  white-space: pre-line;

  ${Media.greaterThan("medium")`
    max-width: 500px;
  `}

  ${Media.greaterThan("1200px")`
    max-width: 760px;
  `}
`;


export type LandingPageProps = {
  headline: string,
  tagline?: string,
  description: string,
  calloutText?: string,
  calloutDark?: boolean
}

const GradientLandingSection = ({ headline, description, calloutDark, calloutText }: LandingPageProps) => {
  return (
    <ContentContainer>
      
      <TextContainer>
        <HeadlineExtended as="h1">{headline}</HeadlineExtended>
        <DescriptionExtended>
          {description}
        </DescriptionExtended>
        <NavButtonLink href={"https://partners.uizard.io/signup_affiliate/6"}>
          {calloutDark ? <Button variant="black">{calloutText ? calloutText : "Sign up for free"}</Button> : <Button solid>{calloutText ? calloutText : "Sign up for free"}</Button>}
        </NavButtonLink>
      </TextContainer>

    </ContentContainer>
  )
}



export default GradientLandingSection