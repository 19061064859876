
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";

import Content from "./layout/Content/Content";
import MediaQuery from "./layout/MediaQuery";
import Headline from "./texts/Headline";
import Description from "./texts/Description";
import Tagline from "./texts/Tagline";


const Container = Styled.div`
margin: 4rem 0 4rem 0;
overflow: hidden; // fix for columns overflow

`;

const Columns = Styled.div`
display: flex;
flex-direction: column-reverse;

${Media.greaterThan("700px")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`}
`;



const ColumnImage = Styled.div`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ColumnText = Styled.div`
  width: 100%;
  margin-left: 0;

  ${Media.greaterThan("large")`
    width: 45%;
    margin-left: 4rem;
  `}
`;

const Text = Styled.div`
  width: 43rem;
  max-width: 100%;
  text-align:left;

  ${Media.greaterThan("large")`
    width: calc(43rem + 9.5rem);
    padding-left: 9.5rem;
  `}
`;

const DescriptionExtended = Styled(Description)`
`;

const ImageA = Styled(Image)`
  width: 32.3rem;
  max-width: calc(100% - 0.75rem);
  margin: 5rem auto 0 auto;

  ${Media.greaterThan("700px")`
    margin: 0 auto
  `}

  ${Media.greaterThan("1280px")`
    width: 50rem;
  `}

  ${Media.greaterThan("760px")`
    max-width: 100%;
  `}

  ${Media.lessThan("1280px")`
    width: 45rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

type HomeFeedbackProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  marginTop?: string;
  marginBottom?: string;
  tagAsTitle?: boolean;

};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  marginTop,
  marginBottom,
  tagAsTitle,

}: HomeFeedbackProps) => (
  <Container marginTop={marginTop} marginBottom={marginBottom}>
    <Content>
      <Columns>
        <MediaQuery lessThan="medium">
          <ColumnImage>
            <ImageA
              fluid={"fluid" in imageB ? imageB.fluid : ""}
              alt={imageAlt}
            />
          </ColumnImage>
        </MediaQuery>

        <ColumnText>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <DescriptionExtended >{description} </DescriptionExtended>
          </Text>
        </ColumnText>
        
        <MediaQuery greaterThan="medium">
          <ColumnImage>
            <ImageA
              fluid={"fluid" in imageA ? imageA.fluid : ""}
              alt={imageAlt}
            />
          </ColumnImage>
        </MediaQuery>

      </Columns>
    </Content>
  </Container>
);
