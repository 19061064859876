import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";
import Button from "../../buttons/Button";
import Description from "../../texts/Description";
import Headline from "../../texts/Headline";

const Container = Styled.div`
  items-align:center;
  text-align:center;
  display: block;
  margin: 0 auto;
`;

const ImgContainer = Styled(Image)`
  width: 25%;
  display: block;
  margin: 0 auto;
`;

const ExtendedDescription = Styled(Description)`
  width: inherit;
  margin: 5rem auto;

  ${Media.greaterThan("medium")`
    max-width: 300px;
  `}

  ${Media.greaterThan("1200px")`
    max-width: 600px;
  `}
`;

const NavButtonLink = Styled.a`
  
`;


export type LandingPageProps = {
  headline: string,
  tagline?: string,
  description: string,
  imageA: GatsbyImageProps,
  imageAlt?: string,
  calloutText?: string,
  calloutDark?: boolean
}

const CenterImage = ({ headline, description, imageA, imageAlt, calloutDark, calloutText }: LandingPageProps) => {
  return (
    <Container>
        {"fluid" in imageA ? <ImgContainer fluid={imageA.fluid} alt="This is your chance—inspire the masses with the magic of AI design, grow your brand, & make money on all referred Uizard Pro sign ups." /> : null}
        <Headline >
          {headline}
        </Headline>
        <ExtendedDescription>{description}</ExtendedDescription>
        <NavButtonLink href={"https://partners.uizard.io/signup_affiliate/6"}>
          {calloutDark ? <Button variant="black" solid>{calloutText ? calloutText : "Sign up for free"}</Button> : <Button solid>{calloutText ? calloutText : "Sign up for free"}</Button>}
        </NavButtonLink>
    </Container>
  )
}

export default CenterImage