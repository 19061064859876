import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import { graphql } from 'gatsby';
import Styled from 'styled-components/macro';

import GradientLandingSection from 'components/GradientLandingSection';
import Content from 'components/layout/Content/Content';
import Layout from 'components/layout/Layout';
import Meta from 'components/meta';
import CenterImage from 'components/pages/affiliate/CenterImage';
import FAQSection from 'components/pages/faq/FAQSection';
import SectionWithLeftImage from 'components/SectionWithLeftImage';
import SectionWithRightImage from 'components/SectionWithRightImage';
import Headline from 'components/texts/Headline';

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 16rem 0 10rem 0;
  overflow: visible;
  items-align:center;
  ${Media.lessThan('large')`
    padding: 0 4rem;
    overflow: visible;
  `}
  
  ${Media.lessThan('medium')`
      margin: 11rem 0 5rem 0;
      overflow: visible;
  `}
  
  ${Media.lessThan('small')`
    padding: 0;
    margin: 9rem 0 4rem 0;
    overflow: visible;
  `}
`;

const CenteredContent = Styled(Content)`
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 10rem;

  ${Media.greaterThan('large')`
    margin-top: 20rem;
    margin-bottom: 20rem;
  `}
  
  & > h5 {
    margin-bottom: 2rem;
  }
`;

const ContentExtended = Styled(Content)`
  text-align: left;
  margin-top: 9rem;
`;

const HeadlineExtended = Styled(Headline)`
  margin: 5rem 0 10rem 0;
  text-align:center;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 500;

  ${Media.greaterThan('medium')`
    margin: 10rem 0 6rem 0;
  `}

  ${Media.greaterThan('1200px')`
    margin: 15rem 0 10rem 0;
  `}

  ${Media.greaterThan('large')`
    font-size: 5.2rem;
    line-height: 5rem;
  `}
`;

const affiliateIndex = ({
  data,
}) => (
  <React.Fragment>
    <Layout>
      <Meta
        title="Uizard's Affiliates and Partners Program | Uizard"
        description="The Uizard Affiliate Program is now accepting applications from creators, publishers, influencers, thought leaders, and more. Sign up to the program for free & start earning."
        url="/affiliate/"
        faqschema={JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'What is the Uizard Affiliate Program?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'The Uizard Affiliate Program is open to thought leaders, media, influencers, content creators, entrepreneurs, and educators who aspire to teach their audience how Uizard can help them ideate, innovate, design, experiment, and more. The program is free to join and allows members to monetize their content and earn commission from each new referred Uizard Pro and Business user.',
              },
            },
            {
              '@type': 'Question',
              name: 'Why partner with Uizard?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: "With no caps on your earning potential and biannual promotions for higher commission rates, it pays to be partnered with Uizard. Maximize your earning potential with no limits. Partner with a tool that sells itself and get increased commission during new Uizard feature drops. Share Uizard's full library of AI features and join the frontline of design innovation.",
              },
            },
            {
              '@type': 'Question',
              name: 'Can I become a Uizard affiliate in any country?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: "The affiliate program is not yet open to UK or EU-based partners due to local data regulations. We're working hard to scale the program globally as soon as possible, so stay tuned! We offer other collaboration avenues to select partners, aligned with our brand and goals! Tell us more about yourself and your partnership proposal here: https://uizard.typeform.com/to/d6zbQaWO.",
              },
            },
            {
              '@type': 'Question',
              name: 'What requirements do I need to meet to apply?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'While these are not formal requirements, we are on the lookout for the following: An established, active website and/or social channel(s). An established audience or community. An existing display of original, engaging content: social posts, videos, blog posts, tutorials, walkthroughs, etc. Experience with Uizard, other tools, or prior content creation in the areas of AI, design, product, entrepreneurship, productivity, etc. All affiliates must agree to our Terms & Conditions and Privacy Policy to start working with us.',
              },
            },
            {
              '@type': 'Question',
              name: 'How will I know if my application is accepted?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: "You will receive a notification to the email address that you use to sign up upon receipt of your application. The Uizard Partnerships Team will review it, in most cases within 5 business days, and, if accepted, you will receive a welcome email to the program with login details to your partner dashboard where you'll find your own referral link, commission information, campaign analytics, brand assets, resources, etc. If your application does not seem to fit with what we are looking for, you will be notified by email.",
              },
            },
            {
              '@type': 'Question',
              name: 'How are referrals tracked?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'If accepted into the program, you will get a personal login to our affiliate software where you can manage your own unique affiliate referral links, campaigns, etc. When sharing Uizard with your audience, always include your affiliate referral link so that your efforts can be tracked and rewarded. The cookie window is 30 days.',
              },
            },
            {
              '@type': 'Question',
              name: 'What is the commission structure?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Upon acceptance into the program, we will assign an offer to you based on a few criteria and provide all details about your unique commission structure. All affiliates, however, can earn without any limits or cap within their assigned offer!',
              },
            },
            {
              '@type': 'Question',
              name: 'How does payment work?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: "Our payment terms are Net 30 and our payment frequency is monthly. We're committed to paying our partners fast and in a reliable, streamlined fashion with invoices and reports accessible all directly within the partner portal.",
              },
            },
            {
              '@type': 'Question',
              name: 'What are Uizard&apos brand guidelines?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Our Partner Kit with assets, copy suggestions, logos, and icons is included in the welcome email to all accepted affiliate partners. At the same time, we invite our partners to create and express with freedom, authenticity, and their own tone of voice, as the experts of their own audience. ',
              },
            },
            {
              '@type': 'Question',
              name: 'How do I become a Uizard expert and stay informed on product updates?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'The Partners Kit will provide a great foundation for understanding Uizard, the tool’s value and common use cases, and we will do our best to keep the creative assets there as up-to-date as possible. Follow us on our social media accounts to stay up to date with our latest product updates.',
              },
            },
            {
              '@type': 'Question',
              name: 'Can I have a promo code to use Uizard for free myself?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'We don’t automatically give out Uizard Pro for free to all affiliates, but if you need it for a specific content creation or promotion plan, we absolutely want to empower you for this! Email us at partnerships@uizard.io with as much detail as possible so that we can validate your proposal.',
              },
            },
            {
              '@type': 'Question',
              name: 'Can I have a promo code to give a Uizard discount to my audience?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'Discounts are strictly available for strategic partnerships. If you’re confident that your proposal will represent a major strategic opportunity for Uizard’s expansion into product manager, designer, founder or consultant audiences, then please propose it to us here: https://uizard.typeform.com/to/d6zbQaWO ',
              },
            },
            {
              '@type': 'Question',
              name: 'What if I have more questions?',
              acceptedAnswer: {
                '@type': 'Answer',
                text: 'It can be difficult to get back to every question and request that comes our way as a fast-growing, thriving program, but rest assured that we are doing our very best to listen to your collective needs and recurring requests, to answer any urgent questions, and to improve the program continuously to your benefit! You can email us at partnerships@uizard.io with questions or feedback.',
              },
            },
          ],
        })}
      />

      <GradientLandingSection
        headline="Join our affiliate program today"
        description="Grow your brand and earn 20% in commission or more by partnering with Uizard, the world's easiest-to-use design and ideation tool. Empower product managers, designers, consultants, and founders to accelerate their success with AI-powered design."
        calloutDark
        calloutText="Start earning now!"
      />

      <Content>
        <HeadlineExtended as="h2">
          How does it work?
        </HeadlineExtended>
      </Content>

      <SectionWithLeftImage
        headline="Register in seconds"
        imageA={data.landingIllustrationA.childImageSharp}
        imageB={data.landingIllustrationA.childImageSharp}
        description="Joining is free and easy. No minimum sales and no fees to worry about. Whether you’re a content creator, an agency, or you work in traditional media, if you have an audience or community that would benefit from a little AI-powered magic, then now is the time to become a trusted Uizard partner."
        imageAlt="illustration of a computer monitor next to text describing how someone can register to become an affiliate with Uizard"
      />

      <SectionWithRightImage
        headline="Quick and easy onboarding"
        imageA={data.landingIllustrationB.childImageSharp}
        imageB={data.landingIllustrationB.childImageSharp}
        description="Get accepted into the program in no time at all. Simply tell us about your goals and motivations for becoming a partner, and share which channels you plan on promoting Uizard through. If you have an audience filled with product managers, designers, consultants or founders then you are the perfect partner for us."
        imageAlt="illustration of a group of people next to text describing how someone can register to become an affiliate with Uizard"
      />

      <SectionWithLeftImage
        headline="Earn 20% in commission or more"
        imageA={data.landingIllustrationC.childImageSharp}
        imageB={data.landingIllustrationC.childImageSharp}
        description="Earn upwards of 20% in commission on every sign up to a Uizard Pro and Business that comes from your referral link. Get paid monthly via PayPal or bank transfer. No minimum sales for payout and no caps on earnings!"
        imageAlt="illustration of a pile of coins next to text describing how someone can register to become an affiliate with Uizard"
        calloutDark
        calloutText="Start earning now"
        link="https://partners.uizard.io/signup_affiliate/6"
      />

      <CenteredContent>
        <HeadlineExtended as="h5">
          Partner with Uizard and join us in sharing the latest developments in AI-powered product design
        </HeadlineExtended>
        {/* <Description>
            Some of Uizard's AI features to "wow" your audience with...
          </Description> */}
      </CenteredContent>

      {/*
        <SectionWithRV
          headline="Convert screenshots to editable designs"
          description=""
          imageA={data.screenshotA.childImageSharp}
          imageB={data.screenshotA.childImageSharp}
          videoName="uizard-scan-screenshot"
          imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        />

        <SectionWithLeftImage
          headline="Turn sketches into digital mockups"
          description=""
          imageA={data.sketchConversion.childImageSharp}
          imageB={data.sketchConversion.childImageSharp}
          imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        />

        <SectionWithRV
          headline="Get text suggestions for your projects"
          description=""
          imageA={data.PM.childImageSharp}
          imageB={data.PM.childImageSharp}
          videoName="uizard-for-project-manager"
          imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        />

        <SectionWithLeftImage
          headline="Create themes from imports, images or urls"
          description=""
          imageA={data.themeExtraction.childImageSharp}
          imageB={data.themeExtraction.childImageSharp}
          imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        />

        <CenterBigImage
          headline="Uizard Autodesigner: Text to Design, automated by AI"
          imageA={data.autodesign.childImageSharp}
          description="To select affiliate partners and influencers, we will be giving early access privileges to Autodesigner!"
          calloutText="Coming Soon"
          calloutDark
        /> */}

      <Container>
        <CenterImage
          headline="Become a Uizard affiliate!"
          imageA={data.landingIllustrationC.childImageSharp}
          description="This is your chance… share the magic of AI-powered product design, grow your brand, and make money on all referred Uizard Pro and Business sign ups."
          calloutText="Start earning now!"
          calloutDark
        />

        <ContentExtended>
          <FAQSection
            title="FAQ | Frequently Asked Questions"
            questions={[
              {
                question: 'What is the Uizard Affiliate Program?',
                answer: 'The Uizard Affiliate Program is open to thought leaders, media, influencers, content creators, entrepreneurs, and educators who aspire to teach their audience how Uizard can help them ideate, innovate, design, experiment, and more. The program is free to join and allows members to monetize their content and earn commission from each new referred Uizard Pro and Business user.',
              },
              {
                question: 'Why partner with Uizard?',
                answer: "With no caps on your earning potential and biannual promotions for higher commission rates, it pays to be partnered with Uizard. Maximize your earning potential with no limits. Partner with a tool that sells itself and get increased commission during new Uizard feature drops. Share Uizard's full library of AI features and join the frontline of design innovation.",
              },
              {
                question: 'Can I become a Uizard affiliate in any country?',
                answer: "The affiliate program is not yet open to UK or EU-based partners due to local data regulations. We're working hard to scale the program globally as soon as possible, so stay tuned! We offer other collaboration avenues to select partners, aligned with our brand and goals! Tell us more about yourself and your partnership proposal here: https://uizard.typeform.com/to/d6zbQaWO.",
              },
              {
                question: 'What requirements do I need to meet to apply?',
                answer: 'While these are not formal requirements, we are on the lookout for the following: An established, active website and/or social channel(s). An established audience or community. An existing display of original, engaging content: social posts, videos, blog posts, tutorials, walkthroughs, etc. Experience with Uizard, other tools, or prior content creation in the areas of AI, design, product, entrepreneurship, productivity, etc. All affiliates must agree to our Terms & Conditions and Privacy Policy to start working with us.',
              },
              {
                question: 'How will I know if my application is accepted?',
                answer: "You will receive a notification to the email address that you use to sign up upon receipt of your application. The Uizard Partnerships Team will review it, in most cases within 5 business days, and, if accepted, you will receive a welcome email to the program with login details to your partner dashboard where you'll find your own referral link, commission information, campaign analytics, brand assets, resources, etc. If your application does not seem to fit with what we are looking for, you will be notified by email.",
              },
              {
                question: 'How are referrals tracked?',
                answer: 'If accepted into the program, you will get a personal login to our affiliate software where you can manage your own unique affiliate referral links, campaigns, etc. When sharing Uizard with your audience, always include your affiliate referral link so that your efforts can be tracked and rewarded. The cookie window is 30 days.',
              },
              {
                question: 'What is the commission structure?',
                answer: 'Upon acceptance into the program, we will assign an offer to you based on a few criteria and provide all details about your unique commission structure. All affiliates, however, can earn without any limits or cap within their assigned offer!',
              },
              {
                question: 'How does payment work?',
                answer: "Our payment terms are Net 30 and our payment frequency is monthly. We're committed to paying our partners fast and in a reliable, streamlined fashion with invoices and reports accessible all directly within the partner portal.",
              },
              {
                question: "What are Uizard's brand guidelines?",
                answer: 'Our Partner Kit with assets, copy suggestions, logos, and icons is included in the welcome email to all accepted affiliate partners. At the same time, we invite our partners to create and express with freedom, authenticity, and their own tone of voice, as the experts of their own audience. ',
              },
              {
                question: 'How do I become a Uizard expert and stay informed on product updates?',
                answer: 'The Partners Kit will provide a great foundation for understanding Uizard, the tool’s value and common use cases, and we will do our best to keep the creative assets there as up-to-date as possible. Follow us on our social media accounts to stay up to date with our latest product updates.',
              },
              {
                question: 'Can I have a promo code to use Uizard for free myself?',
                answer: 'We don’t automatically give out Uizard Pro for free to all affiliates, but if you need it for a specific content creation or promotion plan, we absolutely want to empower you for this! Email us at partnerships@uizard.io with as much detail as possible so that we can validate your proposal. ',
              },
              {
                question: 'Can I have a promo code to give a Uizard discount to my audience?',
                answer: 'Discounts are strictly available for strategic partnerships. If you’re confident that your proposal will represent a major strategic opportunity for Uizard’s expansion into product manager, designer, founder or consultant audiences, then please propose it to us here: https://uizard.typeform.com/to/d6zbQaWO ',
              },
              {
                question: 'What if I have more questions?',
                answer: 'It can be difficult to get back to every question and request that comes our way as a fast-growing, thriving program, but rest assured that we are doing our very best to listen to your collective needs and recurring requests, to answer any urgent questions, and to improve the program continuously to your benefit! You can email us at partnerships@uizard.io with questions or feedback.',
              },
            ]}
          />
        </ContentExtended>
      </Container>
    </Layout>
  </React.Fragment>
);

export default affiliateIndex;

export const data = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    landingIllustrationA: file(
      relativePath: { eq: "affiliate/AffiliateLanding_Illustration01.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    landingIllustrationB: file(
      relativePath: { eq: "affiliate/AffiliateLanding_Illustration02.png" }) {
      childImageSharp {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    landingIllustrationC: file(relativePath: { eq: "affiliate/AffiliateLanding_Illustration03.png" }) {
      childImageSharp {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    screenshotA: file(relativePath: { eq: "ai/HifiConversion-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    sketchConversion: file(relativePath: { eq: "affiliate/Feature_SketchConversion.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    themeExtraction: file(relativePath: { eq: "affiliate/Feature_ThemeExtraction.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    PM: file(relativePath: { eq: "affiliate/uizard-for-pms.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    autodesign: file(relativePath: { eq: "affiliate/Autodesigner_ProductShot.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
