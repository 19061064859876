import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";
import Button from "./buttons/Button";
import MediaQuery from "./layout/MediaQuery";
import Description from "./texts/Description";
import Headline from "./texts/Headline";
import Tagline from "./texts/Tagline";
import RichDescription from "./texts/RichDescription";
import Content from "./layout/Content/Content";


const Container = Styled.div`
  margin: 4rem 0 4rem 0;
  overflow: hidden; // fix for columns overflow
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${Media.greaterThan("700px")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const ColumnImage = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  ${Media.greaterThan("700px")`
    width: 50%;
  `}
`;

const ColumnText = Styled.div`
  width: 100%;
  margin-left: 0;

  ${Media.greaterThan("large")`
    width: 45%;
    margin-left: 4rem;
  `}
`;

const Text = Styled.div`
  width: 43rem;
  max-width: 100%;
  text-align:left;

  ${Media.greaterThan("large")`
    width: calc(43rem + 9.5rem);
    padding-left: 9.5rem;
  `}
`;

const RichDescriptionExtended = Styled(RichDescription)`
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const ImageA = Styled(Image)`
  width: 50%;
  max-width: calc(100% - 0.75rem);
  margin: 5rem auto 0 auto;

  ${Media.greaterThan("700px")`
    margin: 0 auto
  `}

  ${Media.greaterThan("1280px")`
    width: 46rem;
  `}

  ${Media.greaterThan("760px")`
    max-width: 100%;
  `}

  ${Media.lessThan("1280px")`
    width: 40rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

type HomeWireframeProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  link?: string;
  tagAsTitle?: boolean;
  calloutText?: string,
  calloutDark?: boolean
};


const SectionWithLeftImage = ({
  tagline, 
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  link,
  calloutDark, calloutText,
  tagAsTitle,
}: HomeWireframeProps) => (
  <Container>
    <Content>
    <Columns>
      <ColumnImage>
        <MediaQuery lessThan="medium">
          <ImageA
            fluid={"fluid" in imageB ? imageB.fluid : ""}
            alt={imageAlt}
          />
        </MediaQuery>
        <MediaQuery greaterThan="medium">
          <ImageA
            fluid={"fluid" in imageA ? imageA.fluid : ""}
            alt={imageAlt}
          />
        </MediaQuery>
      </ColumnImage>

      <ColumnText>
        <Text>
          {tagline && tagAsTitle && (
            <TaglineExtended as="h1">{tagline}</TaglineExtended>
          )}
          {tagAsTitle && <Headline as="h2">{headline}</Headline>}

          {tagline && !tagAsTitle && (
            <TaglineExtended>{tagline}</TaglineExtended>
          )}
          {!tagAsTitle && <Headline>{headline}</Headline>}

          <RichDescriptionExtended content={description} />

          {link && <NavButtonLink href={"https://partners.uizard.io/signup_affiliate/6"}>
          {calloutDark ? <Button variant="black" solid>{calloutText ? calloutText : "Sign up for free"}</Button> : <Button solid>{calloutText ? calloutText : "Sign up for free"}</Button>}
        </NavButtonLink>
        }
        </Text>
      </ColumnText>
    </Columns>
    </Content>
  </Container>
);

export default SectionWithLeftImage;

